import { Me, Participant, Session } from 'mind-sdk-web';
import { MockParticipant } from '@/model/MockParticipant.ts';
import { generateName } from '@/lib/name-generator.ts';
import { create } from '@/store/create-disposable-store.ts';
import { Conference } from '@/sdk/Conference.ts';

const VOICE_ASSISTANT_PARTICIPANT_NAME = 'Arvis (Voice Assistant)';

export interface ConferenceStore {
  conference: Conference | null;
  setConference: (conference: Conference | null) => void;

  mindToken: string | null;
  setMindToken: (token: string | null) => void;

  session: Session | null;
  setSession: (session: Session | null) => void;

  me: Me | null;
  setMe: (me: Me | null) => void;

  participants: Participant[];
  addParticipant: (participant: Participant) => void;
  addParticipants: (participants: Participant[]) => void;
  updateParticipant: (participant: Participant) => void;
  removeParticipant: (participant: Participant) => void;

  mockParticipants: MockParticipant[];
  addMockParticipant: () => void;
  removeMockParticipant: () => void;
  addVoiceAssistantMockParticipant: () => void;
}

const mergeParticipants = (participants: Participant[]) => [
  ...new Map(participants.map((p) => [p.getId(), p])).values(),
];

const filterParticipant = (participants: Participant[], participant: Participant) =>
  participants.filter((p) => p.getId() !== participant.getId());

export const useConferenceStore = create<ConferenceStore>((set) => ({
  conference: null,
  setConference: (conference: Conference | null) => set({ conference }),

  mindToken: null,
  setMindToken: (token: string | null) => set({ mindToken: token }),

  session: null,
  setSession: (session: Session | null) => set({ session }),

  me: null,
  setMe: (me: Me | null) => set({ me }),

  participants: [],

  addParticipants: (participants: Participant[]) =>
    set((state) => ({ participants: mergeParticipants([...state.participants, ...participants]) })),

  addParticipant: (participant: Participant) =>
    set((state) => ({ participants: mergeParticipants([...state.participants, participant]) })),

  updateParticipant: (participant: Participant) =>
    set((state) => ({
      participants: mergeParticipants([...filterParticipant(state.participants, participant), participant]),
    })),

  removeParticipant: (participant: Participant) =>
    set((state) => ({ participants: filterParticipant(state.participants, participant) })),

  //TODO: Remove these methods
  mockParticipants: [],
  addMockParticipant: () =>
    set((state) => ({
      mockParticipants: [
        ...state.mockParticipants,
        new MockParticipant(Math.random().toString(16).slice(2), generateName()),
      ],
    })),
  removeMockParticipant: () =>
    set((state) => ({
      mockParticipants: state.mockParticipants.filter(
        (p, index) => index !== state.mockParticipants.length - 1 || p.isVoiceAssistant()
      ),
    })),
  addVoiceAssistantMockParticipant: () =>
    set((state) => {
      if (state.mockParticipants.some((p) => p.isVoiceAssistant())) {
        return { mockParticipants: state.mockParticipants };
      }
      return {
        mockParticipants: [
          ...state.mockParticipants,
          new MockParticipant(Math.random().toString(16).slice(2), VOICE_ASSISTANT_PARTICIPANT_NAME, true),
        ],
      };
    }),
}));
