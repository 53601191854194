import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuTrigger } from '@/components/ui/dropdown-menu.tsx';
import CreateConferenceMenu from '@/components/CreateConferenceMenu.tsx';
import { useState } from 'react';
import { LoadingSpinner } from '@/components/ui/spinner.tsx';

export default function CreateConferenceButton() {
  const [processing, setProcessing] = useState(false);
  return (
    <div className="flex gap-2 items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            disabled={processing}
            className="h-12 rounded-3xl bg-gradient-to-r from-blue-500 to-violet-700 hover:opacity-90 focus-visible:ring-0"
          >
            New conference
          </Button>
        </DropdownMenuTrigger>
        {processing && <LoadingSpinner size={42} className="text-muted-foreground" />}
        <CreateConferenceMenu onProcessing={setProcessing} />
      </DropdownMenu>
    </div>
  );
}
