import { ConferenceSDKOptions } from '@/sdk/ConferenceSDKOptions.ts';
import axios, { AxiosInstance } from 'axios';
import {
  DeviceRegistry,
  MediaStream,
  MediaStreamAudioSupplier,
  MediaStreamVideoSupplier,
  MindSDK,
  MindSDKOptions,
  Session,
  SessionOptions,
} from 'mind-sdk-web';
import { Participant, ParticipantRequestStatus } from '@/sdk/Participant.ts';
import { io } from 'socket.io-client';
import { ConferenceHistoryRecord } from '@/sdk/ConferenceHistoryRecord.ts';
import { ChatMessage } from '@/sdk/ChatMessage.ts';
import { JoinRequestRejectedException } from '@/sdk/JoinRequestRejectedException.ts';
import { ParticipantJoinRequest } from '@/sdk/ParticipantJoinRequest.ts';
import { AIMessage } from '@/sdk/AIMessage.ts';
import { TranscriptWord } from '@/sdk/TranscriptWord.ts';
import { SpeakerRegistry } from '@/lib/speaker-registry.ts';

export interface IConferenceSDK {
  createConferenceRoom(): Promise<string>;
  createParticipant(roomId: string): Promise<Participant>;
  createAnonymousAuthToken(roomId: string, name: string): Promise<string>;
  createAnonymousParticipant(anonymousAuthToken: string): Promise<Participant>;
  joinToConference(conferenceUrl: string, participantToken: string): Promise<Session>;
  approveJoinRequest(joinRequestId: string): Promise<void>;
  rejectJoinRequest(joinRequestId: string): Promise<void>;
  getDeviceRegistry(): DeviceRegistry;
  getSpeakerRegistry(): SpeakerRegistry;
  createMediaStream(
    audioSupplier: MediaStreamAudioSupplier | null,
    videoSupplier: MediaStreamVideoSupplier | null
  ): MediaStream | null;
  closeSession(session: Session): void;
  startRecording(roomId: string): Promise<void>;
  stopRecording(roomId: string): Promise<void>;
  createRecordingToken(conferenceId: string): Promise<string>;
  sendMessageToAll(roomId: string, fromParticipantId: string, text: string): Promise<void>;
  getConferencesHistory(): Promise<ConferenceHistoryRecord[]>;
  getRoomChatMessages(roomId: string): Promise<ChatMessage[]>;
  sendAIMessage(roomId: string, aiModel: string, message: string): Promise<void>;
  getAIMessages(roomId: string): Promise<AIMessage[]>;
  getAIModels(): Promise<string[]>;
  getTranscript(conferenceId: string): Promise<TranscriptWord[]>;
}

export class ConferenceSDK implements IConferenceSDK {
  private httpClient: AxiosInstance;
  private options: ConferenceSDKOptions;
  private readonly speakerRegistry: SpeakerRegistry;

  constructor(options: ConferenceSDKOptions) {
    this.verifyOptions(options);
    this.options = options;
    this.httpClient = this.createHttpClient(options);
    this.speakerRegistry = new SpeakerRegistry();
  }

  async initialize(): Promise<void> {
    const mindSdkOptions = new MindSDKOptions();
    mindSdkOptions.setUseVp9ForSendingVideo(true);
    await MindSDK.initialize(mindSdkOptions);

    await this.speakerRegistry.update();
  }

  protected verifyOptions(options: ConferenceSDKOptions): void {
    if (!options.apiPath) {
      throw new Error('apiUrl is not defined');
    }

    if (!options.apiWsPath) {
      throw new Error('apiWsUrl is not defined');
    }

    if (!options.apiToken) {
      throw new Error('apiTokenSource is not defined');
    }
  }

  protected createHttpClient(options: ConferenceSDKOptions): AxiosInstance {
    const instance = axios.create({
      baseURL: `${options.apiPath}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 60000,
    });

    this.addAuthorizationHandler(instance);
    this.addAuthorizationErrorHandler(instance);

    return instance;
  }

  protected addAuthorizationHandler(httpClient: AxiosInstance) {
    httpClient.interceptors.request.use((config) => {
      const token = typeof this.options.apiToken === 'function' ? this.options.apiToken() : this.options.apiToken;
      if (token && !config.headers.Authorization) {
        config.headers.Authorization = 'Bearer ' + token;
      }

      return config;
    });
  }

  protected addAuthorizationErrorHandler(httpClient: AxiosInstance) {
    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          this.options.onAuthError && this.options.onAuthError();
        }

        return Promise.reject(error);
      }
    );
  }

  async createConferenceRoom(): Promise<string> {
    const { data } = await this.httpClient.post<{ id: string }>('/rooms');
    return data?.id;
  }

  async createParticipant(roomId: string): Promise<Participant> {
    const { data } = await this.httpClient.post<Participant>(`/participants`, { roomId });
    return data;
  }

  async createAnonymousAuthToken(roomId: string, name: string): Promise<string> {
    const { data } = await this.httpClient.post<string>('/auth/anonymous', { roomId, name });
    return data;
  }

  async createAnonymousParticipant(anonymousAuthToken: string): Promise<Participant> {
    const { data } = await this.httpClient.post<Participant | ParticipantJoinRequest>(
      `/participants/anonymous`,
      {},
      {
        headers: { Authorization: `Bearer ${anonymousAuthToken}` },
      }
    );

    if (data._ === 'Participant') {
      return data as Participant;
    } else if (data._ === 'ParticipantJoinRequest') {
      return this.waitForJoinApproval(data.id, anonymousAuthToken);
    } else {
      throw new Error('Failed to create participant');
    }
  }

  private async waitForJoinApproval(joinRequestId: string, anonymousAuthToken: string): Promise<Participant> {
    return new Promise((resolve, reject) => {
      const socket = io('/', {
        path: this.options.apiWsPath,
        transports: ['websocket'],
        upgrade: false,
        query: { joinRequestId },
        auth: { token: anonymousAuthToken },
      });

      socket.on('connect_error', (error) => reject(error));

      socket.on('join_request.approved', (participant: Participant) => {
        socket.disconnect();
        resolve(participant);
      });

      socket.on('join_request.rejected', () => {
        socket.disconnect();
        reject(new JoinRequestRejectedException());
      });
    });
  }

  async joinToConference(conferenceUrl: string, participantToken: string): Promise<Session> {
    if (!conferenceUrl) {
      throw new Error('conferenceUrl is not defined');
    }

    if (!participantToken) {
      throw new Error('participantToken is not defined');
    }

    const options = new SessionOptions();
    options.setUseVp9ForSendingVideo(true);
    return MindSDK.join(conferenceUrl, participantToken, options);
  }

  async approveJoinRequest(joinRequestId: string): Promise<void> {
    await this.httpClient.patch(`/participants/join-requests/${joinRequestId}/status`, {
      status: ParticipantRequestStatus.APPROVED,
    });
  }

  async rejectJoinRequest(joinRequestId: string): Promise<void> {
    await this.httpClient.patch(`/participants/join-requests/${joinRequestId}/status`, {
      status: ParticipantRequestStatus.REJECTED,
    });
  }

  getDeviceRegistry(): DeviceRegistry {
    return MindSDK.getDeviceRegistry();
  }

  getSpeakerRegistry(): SpeakerRegistry {
    return this.speakerRegistry;
  }

  createMediaStream(
    audioSupplier: MediaStreamAudioSupplier | null,
    videoSupplier: MediaStreamVideoSupplier | null
  ): MediaStream | null {
    try {
      return MindSDK.createMediaStream(audioSupplier, videoSupplier);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  closeSession(session: Session): void {
    MindSDK.exit2(session);
  }

  async startRecording(roomId: string): Promise<void> {
    await this.httpClient.post(`/rooms/${roomId}/recordings/start`);
  }

  async stopRecording(roomId: string): Promise<void> {
    await this.httpClient.post(`/rooms/${roomId}/recordings/stop`);
  }

  async createRecordingToken(conferenceId: string): Promise<string> {
    const { data } = await this.httpClient.get(`/conferences/${conferenceId}/recordings/tokens`);
    return data.token;
  }

  async sendMessageToAll(roomId: string, fromParticipantId: string, text: string): Promise<void> {
    await this.httpClient.post(`/rooms/${roomId}/messages`, { participantId: fromParticipantId, text });
  }

  async getConferencesHistory(): Promise<ConferenceHistoryRecord[]> {
    const { data } = await this.httpClient.get<ConferenceHistoryRecord[]>(`/conferences/history`);
    return data;
  }

  async getRoomChatMessages(roomId: string): Promise<ChatMessage[]> {
    const { data } = await this.httpClient.get<ChatMessage[]>(`/rooms/${roomId}/messages`);
    return data;
  }

  async sendAIMessage(conferenceId: string, aiModel: string, message: string): Promise<void> {
    await this.httpClient.post(`/ai/messages`, { conferenceId, aiModel, text: message });
  }

  async getAIMessages(roomId: string): Promise<AIMessage[]> {
    const { data } = await this.httpClient.get<AIMessage[]>(`/ai/messages?roomId=${roomId}`);
    return data;
  }

  async getAIModels(): Promise<string[]> {
    const { data } = await this.httpClient.get<string[]>(`/ai/models`);
    return data;
  }

  async getTranscript(conferenceId: string): Promise<TranscriptWord[]> {
    const { data } = await this.httpClient.get<TranscriptWord[]>(`/conferences/${conferenceId}/transcript`);
    return data;
  }
}
