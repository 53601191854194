import { Conference } from '@/sdk/Conference.ts';

export enum PARTICIPANT_ROLE {
  MODERATOR = 'moderator',
  PRESENTER = 'presenter',
  SPEAKER = 'speaker',
  ATTENDEE = 'attendee',
}

export type Participant = {
  _: 'Participant';
  id: string;
  userId: string;
  appId: string;
  name: string;
  role: PARTICIPANT_ROLE;
  conference: Conference;
  token: string;
};

export enum ParticipantRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
