export class User {
  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string
  ) {}

  get fullName(): string {
    return [this.firstName || '', this.lastName || ''].filter(Boolean).join(' ');
  }
}

export class AnonymousUser extends User {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly roomId: string,
    readonly exp: number,
    readonly token: string
  ) {
    super(id, '', name, '');
  }
}
