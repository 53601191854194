import { Microphone, Camera } from 'mind-sdk-web';
import { Speaker } from '@/lib/speaker-registry.ts';

function isVirtualDevice(device: Microphone | Camera): boolean {
  // Filter devices with labels that are known to be virtual devices (list of labels originally generated by ChatGPT)
  const virtualCameraLabels = [
    'OBS Virtual Camera', // OBS Studio
    'ManyCam Virtual Webcam', // ManyCam
    'Snap Camera', // Snap Camera (Snapchat)
    'XSplit VCam', // XSplit VCam
    'EpocCam', // EpocCam (iPhone)
    'DroidCam', // DroidCam (Android)
    'CamTwist', // CamTwist Studio
    'AlterCam', // AlterCam Virtual Camera
    'Logitech Capture', // Logitech Capture Virtual Camera
    'YouCam', // CyberLink YouCam
    'SparkoCam Virtual Camera', // SparkoCam
  ];
  const virtualMicrophoneLabels = [
    'VB-Audio Virtual Cable', // VB-Audio Virtual Cable
    'VoiceMeeter', // VoiceMeeter
    'DroidCam Virtual Audio', // DroidCam (Android)
    'EpocCam Microphone', // EpocCam (iPhone)
    'Virtual Audio Cable', // Virtual Audio Cable (VAC)
    'BlackHole', // BlackHole (macOS)
  ];

  const isCamera = 'setResolution' in device;
  const label = device.getLabel();
  return isCamera ? virtualCameraLabels.includes(label) : virtualMicrophoneLabels.includes(label);
}

export function filterMediaDevices<T extends Microphone | Camera | Speaker>(devices: T[]): T[] {
  const unnecessaryIds = ['communications', 'default'];

  // 1. Filter out devices with invalid deviceId
  let filteredDevices = devices.filter((device) => !!device.getId());

  // 2. Filter out devices that have IDs in the unnecessary list
  // NOTE: Sometimes the browser might leave a single 'default' device,
  // for example, to prompt for permission to access the camera/microphone.
  // In such cases, it's important not to remove the default device.
  // TODO Possibly we should do something that looks like more the way it's done in google meet:
  // remove default devices if there are other devices available with the same 'groupId'. But the 'groupId'
  // property is not available in mind's interfaces.
  if (filteredDevices.length > 1) {
    filteredDevices = filteredDevices.filter((device) => !unnecessaryIds.includes(device.getId()));
  }

  // 3 Filter out virtual video cameras
  filteredDevices = filteredDevices.filter((device) => device instanceof Speaker || !isVirtualDevice(device));

  return filteredDevices;
}

export function fixDeviceDisplayName(name: string): string {
  // replace device id in the format (xxxx:xxxx) at the end of the name
  return name.replace(/\([0-9a-f]{4}:[0-9a-f]{4}\)$/, '');
}

export function decorateDeviceDisplayName<T extends { getLabel: () => string }>(instance: T): T {
  if (!(instance as { isGetLabelDecorated?: boolean }).isGetLabelDecorated) {
    const originalGetLabel = instance.getLabel.bind(instance);
    instance.getLabel = function (): string {
      const originalLabel = originalGetLabel();
      return fixDeviceDisplayName(originalLabel);
    };
    (instance as { isGetLabelDecorated?: boolean }).isGetLabelDecorated = true;
  }

  return instance;
}

export function decorateDevicesDisplayName<T extends { getLabel: () => string }>(instances: T[]): T[] {
  return instances.map(decorateDeviceDisplayName);
}
