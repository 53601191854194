const EVENT_TITLE = 'New iMind Meeting';
const EVENT_DETAILS = 'Click the link to join the meeting:';

export function createEventInGoogleCalendar(conferenceRoomUrl: string, window: WindowProxy) {
  const encodedTitle = encodeURIComponent(EVENT_TITLE);
  const encodedDetails = encodeURIComponent(`${EVENT_DETAILS} ${conferenceRoomUrl}`);
  const encodedLocation = encodeURIComponent(conferenceRoomUrl);

  window.location = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedTitle}&details=${encodedDetails}&location=${encodedLocation}`;
}
