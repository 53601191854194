import { getConferenceService } from '@/service/ConferenceService';
import { useConferenceStore } from '@/store/ConferenceStore';
import { Participant, SessionListener } from 'mind-sdk-web';
import { useUIStore } from '@/store/UIStore.ts';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { useAudioAnalizerStore } from '@/store/AudioAnalizerStore.ts';

export class SessionEventListener extends SessionListener {
  constructor() {
    super();
  }

  onParticipantJoined(participant: Participant) {
    participant.getMediaStream().addAudioConsumer(new AudioStreamListener(participant.getId()));
    useConferenceStore.getState().addParticipant(participant);
  }

  onParticipantExited(participant: Participant) {
    useAudioAnalizerStore.getState().removeAnalyzer(participant.getId());
    useConferenceStore.getState().removeParticipant(participant);

    if (participant.getId() === useUIStore.getState().presentingParticipant?.getId()) {
      useUIStore.getState().unsetPresentingParticipant();
    }
  }

  onMeExpelled() {
    // TODO: Perhaps to show a message to the user insted of redirecting, when the one is kicked out of the conference
    window.location.href = '/';
  }

  onConferenceRecordingStarted(): void {
    useUIStore.getState().setRecordingEnabled(true);
  }

  onConferenceRecordingStopped(): void {
    useUIStore.getState().setRecordingEnabled(false);
  }

  onConferenceEnded() {
    getConferenceService()?.closeSession();
    window.location.href = '/';
  }

  onParticipantMediaChanged(participant: Participant): void {
    useConferenceStore.getState().updateParticipant(participant);
  }

  onParticipantSecondaryMediaChanged(participant: Participant): void {
    useConferenceStore.getState().updateParticipant(participant);
    if (participant.isStreamingSecondaryVideo()) {
      useUIStore.getState().setPresentingParticipant(participant);
    } else {
      useUIStore.getState().unsetPresentingParticipant();
    }
  }
}
