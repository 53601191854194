interface RetryConfig {
  maxAttempts: number;
  initialDelay: number;
  maxDelay: number;
}

export async function retryWithBackoff<T>(
  operation: () => Promise<T>,
  config: RetryConfig = {
    maxAttempts: 3,
    initialDelay: 1000,
    maxDelay: 5000,
  }
): Promise<T> {
  let attempt = 1;
  let delay = config.initialDelay;

  while (attempt <= config.maxAttempts) {
    try {
      return await operation();
    } catch (error) {
      if (attempt === config.maxAttempts) {
        throw error;
      }

      console.warn(`Attempt ${attempt} failed, retrying in ${delay}ms...`, error);

      await new Promise((resolve) => setTimeout(resolve, delay));
      delay = Math.min(delay * 2, config.maxDelay);
      attempt++;
    }
  }

  throw new Error('Should never reach here due to throw in loop');
}
