import { DeviceRegistryListener } from 'mind-sdk-web';
import { DeviceService } from '@/service/DeviceService';

export class DeviceRegistryEventListener implements DeviceRegistryListener {
  private readonly _deviceService: DeviceService;

  constructor(deviceService: DeviceService) {
    this._deviceService = deviceService;
  }

  async onDeviceRegistryChanged(): Promise<void> {
    await this._deviceService.setupMediaDevices();
  }
}
