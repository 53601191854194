import { Camera, Microphone } from 'mind-sdk-web';
import { Speaker } from '@/lib/speaker-registry.ts';

export class DefaultDeviceRecogniser {
  private devicesFromNavigator: MediaDeviceInfo[] = [];

  async updateFromNavigator(): Promise<void> {
    this.devicesFromNavigator = await navigator.mediaDevices.enumerateDevices();
  }

  markSystemDefaultDevices<T extends Microphone | Camera | Speaker>(devices: T[]): T[] {
    devices.forEach((device) => {
      const deviceWithDefault = device as T & { _systemDefault?: boolean };

      // Find the corresponding device from `devicesFromNavigator` using the same deviceId
      const navigatorDevice = this.devicesFromNavigator.find((navDevice) => navDevice.deviceId === device.getId());

      // If no matching device is found, remove the `_systemDefault` marker
      if (!navigatorDevice) {
        delete deviceWithDefault._systemDefault;
        return;
      }

      // Find all devices in the same group using groupId
      const groupDevices = this.devicesFromNavigator.filter(
        (navDevice) => navDevice.kind === navigatorDevice.kind && navDevice.groupId === navigatorDevice.groupId
      );

      // Check if any device in the group has "default" in its label
      const isDefault = groupDevices.some((navDevice) => navDevice.deviceId === 'default');

      // Mark the device as system default if found, otherwise remove the marker
      if (isDefault) {
        deviceWithDefault._systemDefault = true;
      } else {
        delete deviceWithDefault._systemDefault;
      }
    });

    return devices;
  }
}

export async function markSystemDefaultDevices<T extends Microphone | Camera | Speaker>(devices: T[]): Promise<T[]> {
  return devices;
}

export function isMarkedAsSystemDefault<T extends Microphone | Camera | Speaker>(device: T): boolean {
  const deviceWithDefault = device as T & { _systemDefault?: boolean };
  return deviceWithDefault._systemDefault === true;
}
