import { ConferenceSDK, IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { getAuthService } from '@/service/AuthService.ts';

export const createConferenceSDK = async (): Promise<IConferenceSDK> => {
  const authService = getAuthService();

  const sdk = new ConferenceSDK({
    apiPath: import.meta.env.VITE_APP_API_PATH,
    apiWsPath: import.meta.env.VITE_APP_API_WS_PATH,
    apiToken: () => authService.getAccessToken() || '',
    onAuthError: () => authService.login(),
  });

  await sdk.initialize();
  return sdk;
};
