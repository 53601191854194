import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage.tsx';
import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import MessageSheet from '@/components/MessageSheet.tsx';
import { getAuthService, useAuthService } from '@/service/AuthService.ts';
import { getConferenceService, initializeConferenceService } from '@/service/ConferenceService.ts';
import { Toaster } from '@/components/ui/sonner.tsx';
import { createConferenceSDK } from '@/lib/conference-sdk.ts';
import { initializeDeviceService } from '@/service/DeviceService.ts';
import AuthGuard from '@/components/AuthGuard.tsx';

const AuthPage = lazy(() => import('@/pages/AuthPage.tsx'));
const HistoryPage = lazy(() => import('@/pages/HistoryPage.tsx'));
const VersionPage = lazy(() => import('@/pages/VersionPage.tsx'));
const ConferencePage = lazy(() => import('@/pages/ConferencePage.tsx'));

const DEFAULT_FALLBACK = <MessageSheet message="Loading..." />;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<HomePage />} />

      <Route
        path="auth"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <AuthPage />
          </Suspense>
        }
      />

      <Route element={<AuthGuard />}>
        <Route
          path="history"
          element={
            <Suspense fallback={DEFAULT_FALLBACK}>
              <HistoryPage />
            </Suspense>
          }
        />
      </Route>

      <Route
        path=":roomId"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <ConferencePage />
          </Suspense>
        }
      />

      <Route
        path="version"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <VersionPage />
          </Suspense>
        }
      />
    </Route>
  )
);

function App() {
  const authService = useAuthService();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [servicesInitialized, setServicesInitialized] = useState(false);

  const scheduleTokenRefresh = useCallback((): void => {
    const expIn: number | null = authService.getAccessTokenExpiresIn();
    if (expIn) {
      const timeout = expIn > 5000 ? expIn - 5000 : 0; // 5 seconds before expiry
      timeoutIdRef.current = setTimeout(async () => {
        await authService.refresh();
        scheduleTokenRefresh();
      }, timeout);
    }
  }, [authService]);

  const initializeServices = useCallback(async () => {
    const sdk = await createConferenceSDK();
    initializeDeviceService(sdk);
    initializeConferenceService(sdk);
    setServicesInitialized(true);
  }, []);

  useEffect(() => {
    (async () => {
      scheduleTokenRefresh();
      await initializeServices();

      return () => timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    })();
  }, [initializeServices, scheduleTokenRefresh]);

  // Reload the page when the user returns from another tab
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener('load', async () => {
      const authService = getAuthService();
      await authService.getUser();
    });
  }, []);

  // Clean up on conference leave
  useEffect(() => {
    const handleBeforeUnload = () => {
      const conferenceService = getConferenceService();
      if (conferenceService) {
        conferenceService.closeSession();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Handle page visibility changes
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const conferenceService = getConferenceService();
        if (!conferenceService) {
          await initializeServices();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [initializeServices]);

  if (!servicesInitialized) {
    return <MessageSheet message="Initializing..." />;
  }

  return (
    <>
      <RouterProvider router={router} />
      <Toaster position="top-right" />
    </>
  );
}

export default App;
