import { Camera, Me, Microphone } from 'mind-sdk-web';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { decorateDevicesDisplayName, filterMediaDevices } from '@/lib/filter-media-devices.ts';
import { ScreenEventListener } from '@/event/ScreenEventListener.ts';
import { useDeviceStore } from '@/store/DeviceStore.ts';
import { MicrophoneEventListener } from '@/event/MicrophoneEventListener.ts';
import { CameraEventListener } from '@/event/CameraEventListener.ts';
import { toast } from 'sonner';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { useConferenceStore } from '@/store/ConferenceStore.ts';
import { useSelectedDeviceStore } from '@/store/SelectedDeviceStore.ts';
import { DeviceRegistryEventListener } from '@/event/DeviceRegistryEventListener.ts';
import { Speaker } from '@/lib/speaker-registry.ts';
import { create } from 'zustand';
import { DefaultDeviceRecogniser, isMarkedAsSystemDefault } from '@/lib/default-device-recogniser.ts';

export const getDeviceService = (): DeviceService | undefined => useDeviceServiceStore.getState().deviceService;
export const useDeviceService = (): DeviceService | undefined => useDeviceServiceStore((state) => state.deviceService);
export const initializeDeviceService = (sdk: IConferenceSDK): void => {
  useDeviceServiceStore.getState().setDeviceService(new DeviceServiceImpl(sdk));
};

export interface DeviceService {
  setupMediaDevices(): Promise<void>;
  useMicrophone(microphone: Microphone): Promise<void>;
  toggleMicrophone: () => Promise<void>;
  useCamera(camera: Camera): Promise<void>;
  toggleCamera: () => Promise<void>;
  useSpeaker(speaker: Speaker): Promise<void>;
  setupPrimaryMediaStream(me: Me | null): Promise<void>;
  setupSecondaryMediaStream(me: Me | null): Promise<void>;
}

export class DeviceServiceImpl implements DeviceService {
  private readonly _sdk: IConferenceSDK;
  private readonly _defaultDeviceRecogniser = new DefaultDeviceRecogniser();

  constructor(sdk: IConferenceSDK) {
    this._sdk = sdk;
  }

  async setupMediaDevices(): Promise<void> {
    await this._defaultDeviceRecogniser.updateFromNavigator();

    const deviceStore = useDeviceStore.getState();
    deviceStore.setAvailableMicrophones(
      this._defaultDeviceRecogniser.markSystemDefaultDevices(
        decorateDevicesDisplayName(filterMediaDevices(this._sdk.getDeviceRegistry().getMicrophones()))
      )
    );
    deviceStore.setAvailableCameras(
      this._defaultDeviceRecogniser.markSystemDefaultDevices(
        decorateDevicesDisplayName(filterMediaDevices(this._sdk.getDeviceRegistry().getCameras()))
      )
    );
    deviceStore.setAvailableSpeakers(
      this._defaultDeviceRecogniser.markSystemDefaultDevices(
        decorateDevicesDisplayName(filterMediaDevices(this._sdk.getSpeakerRegistry().getSpeakers()))
      )
    );
    const listener = new DeviceRegistryEventListener(this);
    this._sdk.getDeviceRegistry().setListener(listener);
    this._sdk.getSpeakerRegistry().setListener(listener);

    await this._useDefaultMicrophone();
    await this._useDefaultCamera();
    await this._useDefaultSpeaker();
  }

  private async _useDefaultMicrophone(): Promise<void> {
    const deviceStore = useDeviceStore.getState();

    const microphones: Microphone[] = deviceStore.availableMicrophones;
    const prevSelectedMicId = useSelectedDeviceStore.getState().selectedMicrophoneId;
    const mic =
      microphones.find((mic) => mic.getId() === prevSelectedMicId) ||
      microphones.find((mic) => isMarkedAsSystemDefault(mic)) ||
      microphones[0];

    if (deviceStore.microphone?.getId() !== mic?.getId()) {
      await this._useMicrophoneImpl(mic);
    }
  }

  private async _useDefaultCamera(): Promise<void> {
    const deviceStore = useDeviceStore.getState();

    const cameras = deviceStore.availableCameras;
    const prevSelectedCamId = useSelectedDeviceStore.getState().selectedCameraId;
    const cam =
      cameras.find((cam) => cam.getId() === prevSelectedCamId) ||
      cameras.find((cam) => isMarkedAsSystemDefault(cam)) ||
      cameras[0];

    if (deviceStore.camera?.getId() !== cam?.getId()) {
      await this._useCameraImpl(cam);
    }
  }

  private async _useDefaultSpeaker(): Promise<void> {
    const deviceStore = useDeviceStore.getState();

    const speakers = deviceStore.availableSpeakers;
    const prevSelectedSpeakerId = useSelectedDeviceStore.getState().selectedSpeakerId;

    const speaker =
      speakers.find((device) => device.getId() === prevSelectedSpeakerId) ||
      speakers.find((speaker) => isMarkedAsSystemDefault(speaker)) ||
      speakers[0];
    if (deviceStore.speaker?.getId() !== speaker?.getId()) {
      await this._useSpeakerImpl(speaker);
    }
  }

  async useMicrophone(microphone: Microphone): Promise<void> {
    await this._useMicrophoneImpl(microphone);
    useSelectedDeviceStore.getState().setMicrophoneId(microphone.getId());
  }

  private async _useMicrophoneImpl(microphone: Microphone): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();

    const prevMic = deviceStoreState.microphone;

    try {
      prevMic && (await prevMic.release());

      if (microphone) {
        if (deviceStoreState.microphoneEnabled) {
          await microphone.acquire();
        }

        microphone.setNoiseSuppression(true);
        microphone.setListener(new MicrophoneEventListener());
      }

      deviceStoreState.setMicrophone(microphone);

      const me = useConferenceStore.getState().me;
      await this.setupPrimaryMediaStream(me);

      // We need to port this kludge from MindSDK to retrieve actual speaker list after getting
      // permissions to use the microphone. Reproducible with Chrome+Win+AnonymousTab
      // See: https://gitlab.com/mindlabs/api/sdk/web/-/blob/master/lib/Microphone.js?ref_type=heads#L140
      if (deviceStoreState.availableMicrophones.length === 1) {
        this._sdk
          .getSpeakerRegistry()
          .update()
          .catch((e) => {
            toast.error(`Can't update speakers: ${e.message}`);
          });
      }
    } catch (e) {
      toast.error(`Can't switch microphone: ${e.message}`);
    }
  }

  async toggleMicrophone(): Promise<void> {
    const state = useDeviceStore.getState();
    try {
      if (!state.microphone) return;

      state.microphoneEnabled ? await state.microphone?.release() : await state.microphone?.acquire();
      state.microphone?.setMuted(state.microphoneEnabled);
      state.setMicrophoneEnabled(!state.microphoneEnabled);
    } catch (e) {
      toast.error(`Can't ${state.microphoneEnabled ? 'stop' : 'start'} microphone: ${e.message}`);
    }
  }
  async useCamera(camera: Camera): Promise<void> {
    await this._useCameraImpl(camera);
    useSelectedDeviceStore.getState().setCameraId(camera.getId());
  }

  private async _useCameraImpl(camera: Camera): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();
    const prevCamera = deviceStoreState.camera;

    try {
      prevCamera && (await prevCamera.release());

      if (camera) {
        if (deviceStoreState.cameraEnabled) {
          await camera.acquire();
        }

        camera.setListener(new CameraEventListener());
      }

      deviceStoreState.setCamera(camera);

      const me = useConferenceStore.getState().me;
      await this.setupPrimaryMediaStream(me);
    } catch (e) {
      toast.error(`Can't switch camera: ${e.message}`);
    }
  }

  async toggleCamera(): Promise<void> {
    const state = useDeviceStore.getState();

    try {
      if (!state.camera) return;

      state.cameraEnabled ? await state.camera?.release() : await state.camera?.acquire();
      state.setCameraEnabled(!state.cameraEnabled);
    } catch (e) {
      toast.error(`Can't ${state.cameraEnabled ? 'stop' : 'start'} camera: ${e.message}`);
    }
  }

  async useSpeaker(speaker: Speaker): Promise<void> {
    await this._useSpeakerImpl(speaker);
    useSelectedDeviceStore.getState().setSpeakerId(speaker.getId());
  }

  private async _useSpeakerImpl(speaker: Speaker): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();
    deviceStoreState.setSpeaker(speaker);
  }

  async setupPrimaryMediaStream(me: Me | null): Promise<void> {
    const microphone = useDeviceStore.getState().microphone;
    const camera = useDeviceStore.getState().camera;

    const primaryMediaStream = microphone || camera ? this._sdk.createMediaStream(microphone, camera) : null;
    useDeviceStore.getState().setPrimaryMediaStream(primaryMediaStream);

    if (me) {
      primaryMediaStream && primaryMediaStream.addAudioConsumer(new AudioStreamListener(me.getId()));
      me.setMediaStream(primaryMediaStream);
    }
  }

  async setupSecondaryMediaStream(me: Me | null): Promise<void> {
    const deviceRegistry = this._sdk.getDeviceRegistry();
    const screen = deviceRegistry.getScreen();
    if (screen) {
      screen.setListener(new ScreenEventListener());
      useDeviceStore.getState().setScreen(screen);
    }

    const secondaryMediaStream = this._sdk.createMediaStream(screen, screen);
    useDeviceStore.getState().setSecondaryMediaStream(secondaryMediaStream);
    if (secondaryMediaStream && me) {
      me.setSecondaryMediaStream(secondaryMediaStream);
    }
  }
}

interface DeviceServiceStore {
  deviceService: DeviceService | undefined;
  setDeviceService: (service: DeviceService) => void;
}

const useDeviceServiceStore = create<DeviceServiceStore>((set) => ({
  deviceService: undefined,
  setDeviceService: (service: DeviceService) => set({ deviceService: service }),
}));
